.img-container {
  position: relative;
}
.img-container img {
  width: 100%;
  height: 80vh;
  opacity: 0.7;
}

.img-abttext {
  position: absolute;
  color: white;
  bottom: 35%;
  right: 10%;
  /* line-height: 1rem; */
}

.gradient-text {
  background: linear-gradient(45deg, #e77241, #ff9a64, #d96030);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  font-size: 4rem;
  font-weight: 700;
}

