/* .navbar {
  width: calc(100% - 150px);
  background: #2e2e2e !important;
  height: 15vh;
  margin: 40px auto;
  position: relative;
  border-radius: 30px 0px 30px 0px;
  display: flex;
  position: absolute; 
  top: 0; 
  left: 0; 
  right: 0;
  z-index: 10; 
  background-color: rgba(255, 255, 255, 0.8); 
  padding: 10px; 
} */

.navbar {
  width: calc(100% - 150px);
  background: rgba(15, 15, 15, 0.596); /* Semi-transparent white */
  height: 14vh;
  margin: 10px auto;
  position: relative;
  border-radius: 30px 0px 30px 0px;
  display: flex;
  position: absolute; /* Position the navbar absolutely */
  top: 0; /* Align to the top */
  left: 0; /* Align to the left */
  right: 0; /* Stretch to the right */
  z-index: 10; /* Ensure it appears above the SwiperSlider */
  backdrop-filter: blur(10px);
  -webkit-backdrop-filter: blur(10px); 
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1); /* Optional: a subtle shadow for depth */
  padding: 10px; /* Adjust padding as needed */
  border: 1px solid rgba(255, 255, 255, 0.3); 
}


.main-link {
  display: flex;
  flex-direction: row;
  gap: 40px;
  align-items: center;
  background-color: transparent;
  position: relative;
  left: -50px;
}

.main-link a {
  text-decoration: none; /* Removes underline */
  background-color:transparent;
  color: white; /* Sets text color */
  padding: 5px 10px;
  font-weight: 700;
  font-size: 1.2rem;
}

.main-link a:hover {
  color: #ff6b00; /* Changes text color on hover */
}

/* .basketlogo {
  width: 10vw;
  height: 20vh;
  border: 10px solid #2e2e2e ; 
  position: absolute;
  top: 50%;
  left: 50%;
  border-radius: 50%;
  transform: translate(-50%, -50%);
} */
  

.basketlogo {
  width: 9vw;
  height: 18vh;
  /* border: 10px solid rgba(255, 255, 255, 0.003); */
  position: absolute;
  top: 50%;
  left: 50%;
  border-radius: 50%;
  transform: translate(-50%, -50%);
  background: rgba(255, 255, 255, 0);
  backdrop-filter: blur(15px);
  -webkit-backdrop-filter: blur(10px);
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  animation: rotate 60s linear infinite; 
}

/* Keyframes for rotation */
@keyframes rotate {
  from {
      transform: translate(-50%, -50%) rotate(0deg);
  }
  to {
      transform: translate(-50%, -50%) rotate(360deg);
  }
}

  /* #894526 */
  /* #A95544 */
.sankalplogo {
  width: 9.9vw;
  height: 12.5vh;
  background-color: transparent;
  padding: 10px;
  margin-left: 35px;
  /* margin-bottom: 10px; */
}

.icons-nav {
  color: white;
  background-color: transparent;
  margin: auto;
  display: flex;
  gap: 50px;
  position: relative;
  right: 200px;
}

.icon-nav {
  font-size: 2.8rem;
  background-color: transparent;
  border-radius: 10px;
  cursor: pointer;
  /* color: #ff6b00; */
}

.insta {
  background: linear-gradient(45deg, #c13584, #e1306c, #f56040, #fcaf45);
}

.youtube {
  color: #ff0000;
}

.facebook {
  color: #1877F2; 
}

/* .icon-nav:hover {
  color: white;
} */

/* #F4A35D */
/* #E77241 */
/* #E88606 */
/* #D75F02 */
/* #BB4802 */
/* #F8CC6C */
/* #cc5402 */
