/* .testimonial-container {
    position: relative;
    width: 100%;
    overflow: hidden;
  }
  
  .testimony-div {
    display: flex;
    overflow-x: auto;
    scroll-behavior: smooth;
    padding: 20px 0;
  }
  
  .test-in {
    min-width: 300px;
    margin-right: 20px;
    background: #fff;
    padding: 40px 20px 80px 20px;
    text-align: center;
    border-radius: 5px;
    transition: 0.5s;
  }
  
  .scroll-btn {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    background-color: #05afec;
    color: white;
    border: none;
    padding: 10px 20px;
    cursor: pointer;
    border-radius: 5px;
    z-index: 1;
  }
  
  .scroll-btn:hover {
    background-color: #004f7c;
  }
  
  .prev-btn {
    left: 10px;
  }
  
  .next-btn {
    right: 10px;
  }
  

.test-in .test-icon {
  font-size: 60px;
  position: absolute;
  color: #ccc;
  transform: rotateY(0deg);
  transition: 0.5s;
}

.test-in .test-icon:nth-child(1) {
  left: 20px;
  top: -30px;
}

.test-in .test-icon:nth-child(2) {
  right: 20px;
  bottom: -30px;
}

.test-in p {
  font-size: 15px;
  line-height: normal;
}

.test-image {
  width: 120px;
  height: 120px;
  background: #fff;
  position: absolute;
  left: 40%;
  right: 0;
  bottom: -50px;
  border-radius: 100px;
}

.test-image img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 100px;

}

.test-btn {
  width: 100%;
  height: auto;
  position: absolute;
  left: 0;
  right: 0;
  bottom: -110px;
}

.test-btn h1 {
  font-size: 20px;
  color: #152644;
  font-weight: 600;
}
.test-btn h1 span {
  font-size: 14px;
  color: #666;
  font-weight: 400;
}

.test-in:hover {
  transform: translateY(-10%);
  transition: 0.5s;
}

.test-in:hover .test-icon:nth-child(1) {
  transition: 0.5s;
  transform: rotateY(360deg);
  color: #05afec;
}

.test-in:hover .test-icon:nth-child(2) {
  transition: 0.5s;
  transform: rotateY(360deg);
  color: #05afec;
  color: #741;
} */

/* Container for the testimonial */
.testimonial-container {
  position: relative;
  overflow: hidden;
  width: calc(100% - 140px);
  margin: 0px auto;
  padding: 0px 30px;
}

.test-head {
  color: #333333;
  text-align: start;
  font-size: 8rem;
  margin-top: 40px;
  font-weight: 700;
  -webkit-text-stroke: 3px #666666;
}

.head-anime {
  display: flex;
}

/* Scrollable area */
.testimony-div {
  display: flex;
  gap: 20px; /* Spacing between testimonials */
  height: 40vh;
  overflow-x: auto;
  scroll-behavior: smooth;
  padding: 20px;
  margin-top: 30px;
  scrollbar-width: none; /* Hide scrollbar in Firefox */
}

.testimony-div::-webkit-scrollbar {
  display: none; /* Hide scrollbar in Chrome, Safari */
}

/* Individual testimonial */
.test-in {
  flex: 0 0 calc(33.33% - 20px); /* 3 sections in one slide, accounting for the gap */
  background: black;
  color: white;
  border: 3px solid #e77241;
  height: 20vh;
  padding: 40px 20px;
  border-radius: 10px;
  text-align: center;
  position: relative;
  transition: transform 0.3s ease;
}

/* Hover effect for individual testimonials */
.test-in:hover {
  transform: translateY(-10px);
}

/* Quote icons */
.test-icon {
  font-size: 40px;
  position: absolute;
  color: white;
  transition: transform 0.3s ease, color 0.3s ease;
}

.test-icon:nth-child(1) {
  left: 20px;
  top: -20px;
}

.test-icon:nth-child(2) {
  right: 20px;
  bottom: -20px;
}

/* Hover effects for icons */
.test-in:hover .test-icon {
  transform: rotateY(360deg);
  color: #333333;
}

/* Testimonial content */
.test-in p {
  font-size: 15px;
  line-height: normal;
}

.test-image {
  width: 120px;
  height: 120px;
  background: black;
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  top: 100%;
  transform: translateY(-50%) translateX(-50%);
  border-radius: 100px;
  border: 3px solid #e77241;
  transition: all 0.3s ease;
  overflow: hidden;
}

.test-image img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 100px;
  transition: opacity 0.3s ease;
}

/* Text overlay on hover */
.test-image:hover img {
  opacity: 0; /* Hide the image on hover */
  transition: opacity 0.3s ease;
}

.test-image:hover .test-btn {
  opacity: 1;
  visibility: visible;
  background: rgba(0, 0, 0, alpha); /* Black background with transparency */
}

.test-btn {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  opacity: 0;
  visibility: hidden;
  text-align: center;
  background: none;
  color: #fff;
  transition: opacity 0.3s ease;
}

.test-btn h1 {
  font-size: 15px;
  color: white;
  font-weight: 600;
  line-height: 20px;
}
.test-btn h1 span {
  font-size: 8px;
  color: white;
  font-weight: 400;
  line-height: 20px;
}

/* Scroll buttons */
.scroll-btn {
  position: absolute;
  top: 94%;
  transform: translateY(-50%);
  background-color: #666666;
  color: #fff;
  border: none;
  padding: 10px 15px;
  cursor: pointer;
  border-radius: 20px;
  z-index: 1;
}

.scroll-btn:hover {
  background-color: #333333;
}

.prev-btn {
  left: 10px;
}

.arrow-icon-left {
  transition: transform 0.3s ease;
}

.arrow-icon-left:hover{
  transform: translatex(-5px);
}
.arrow-icon-right {
  transition: transform 0.3s ease;
}

.arrow-icon-right:hover{
  transform: translatex(5px);
}


.next-btn {
  right: 10px;
}

.circle-anime {
  display: flex;
  justify-items: center;
  align-items: center;
  background-color: black;
}

.circular-text-wrapper {
  position: relative;
  width: 300px;
  height: 300px;
}

#circle-path {
  fill: transparent;
}

.circular-text {
  font-size: 22px;
  fill: white;
  animation: spin-text 25s linear infinite;
  transform-origin: center;
}

@keyframes spin-text {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
