* {
  font-family: "Segoe UI", Tahoma, Geneva, Verdana, sans-serif;
}

.about-flexdiv {
  display: flex;
  flex-direction: row;
  width: calc(100% - 140px);
  height: 96vh;
  margin: 30px auto;
  padding: 0px 30px;
  box-sizing: border-box;
  overflow-x: hidden;
  margin-bottom: 0;
}

.about-question {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 8px;
}

.about-text {
  color: #e77241;
  width: 50%;
}

.about-text p {
  color: white;
}

.about-text h1 {
  color: #e77241;
}

.coach-text {
  border: 3px solid #e77241;
  border-radius: 5px;
  padding: 20px;
  height: 13vw;
  /* margin-top: 10%; */
}

.coach-text p {
  color: white;
}

.coach-text img {
  width: 5vw;
  height: 10vh;
  border-radius: 50%;
  object-fit: cover;
}

.about-question h3 {
  font-size: 5rem;
  font-weight: 700;
  margin: 0;
  color: #333333;
}

.quotes-icon {
  position: relative;
  top: -20px;
  left: 65%;
  font-size: 5.5rem;
  background-color: black;
  padding: 10px;
  color: #e77241;
}

.basketball-icon {
  color: #e77241;
}

.about-sectionTwo {
  position: relative;
  left: 15%;
  margin-bottom: 0;
}

.about-imageone {
  width: 35vw;
  height: 55vh;
  object-fit: cover;
}

.about-imagetwo {
  position: relative;
  top: -70px;
  left: -150px;
  width: 25vw;
  height: 35vh;
  background-color: black;
  padding: 15px;
  border-radius: 10px;
  margin-bottom: 0;
  object-fit: cover;
}

.about-imageone, .about-imagetwo {
  transition: all 0.3s ease-in-out; /* Adds smooth transition */
}

.about-imageone:hover,
.about-imageone:focus {
  transform: scale(1.1); /* Slightly enlarges the image */
  filter: brightness(1.2); /* Brightens the image */
}

.about-imagetwo:hover,
.about-imagetwo:focus {
  transform: scale(1.1); /* Enlarges and rotates slightly */
  background-color: #333; /* Changes background color */
  box-shadow: 0px 8px 15px rgba(0, 0, 0, 0.3); /* Adds shadow effect */
}


.coach-info {
  display: flex;
  line-height: 0.5rem;
  gap: 20px;
}

/* .marqueeabout{
  color: #333333;
  text-align: center;
  font-size: 10rem;
  font-weight: 900;
} */

.marquee-container {
  width: 100%;
  overflow: hidden; /* Hides the content that moves out of the container */
}

.marquee-content {
  display: flex;
  gap: 100px;
}

.marquee-text {
  margin-right: 150px;
  color: #333333;
  text-align: center;
  font-size: 8rem;
  font-weight: 700;
  text-align: center;
}

.basketicon {
  width: 9vw;
  height: 18vh;
  border-radius: 50%;
  position: relative;
  top: 80px;
  left: 70px;
  color: #333333;
  animation: rotate 20s linear infinite;
}

/* Keyframes for rotation */
@keyframes rotate {
  from {
    transform: translate(-50%, -50%) rotate(360deg);
  }
  to {
    transform: translate(-50%, -50%) rotate(0deg);
  }
}
/* #F4A35D */
/* #E77241 */
/* #E88606 */
/* #D75F02 */
/* #BB4802 */
/* #F8CC6C */
/* #cc5402 */
