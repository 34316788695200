/* .container {
    width: calc(100% - 140px);
    margin: 0 auto;
    padding: 20px 30px;
    box-sizing: border-box;
    border-radius: 30px;
    overflow: hidden;
  }
  
  .container img {
    width: 100%;
    height: 80vh;
    border-radius: 15px;
    transition: transform 1.5s ease-in-out; 
  } */


  /* .container {
    width: 100%;
    height: 95vh; 
    position: relative;
    overflow: hidden;
 
}

.swiper_container {
    width: 100%;
    height: 100%;
   
}

.swiper-slide {
    display: flex;
    justify-content: center;
    align-items: center;
    
    height: 100%; 
    position: relative; 
}

.swiper-slide img {
  width: 100%;
  height: 100%; 
  border-radius: 10px; 
  object-fit: fill; 
  
  transition: opacity 0.6s ease, filter 0.6s ease;
}

.swiper-slide::after {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
   
    background-color: rgba(0, 0, 0, 0.299);
    border-radius: 10px;
    z-index: 1; 
    pointer-events: none; 
}


@keyframes slide-backward-forward {
  0% {
      transform: scale(0.9) translateZ(-50px); 
      opacity: 0;
  }
  50% {
      transform: scale(1) translateZ(0); 
      opacity: 1;
  }
  100% {
      transform: scale(1);
      opacity: 1;
  }
}

.fade-in {
  opacity: 1;
  animation: slide-backward-forward 0.6s ease forwards; 
}

.fade-out {
  opacity: 0;
  transform: scale(1);
}

.swiper-button-next,
.swiper-button-prev {
    color: #fff; 
    font-size: 30px; 
    width: 40px; 
    height: 40px; 
    background-color: rgba(0, 0, 0, 0.5); 
    border-radius: 50%; 
    color: #894526; 
    display: flex;
    justify-content: center;
    align-items: center;
    transition: background-color 0.3s ease; 
    z-index: 2; 
}

.swiper-button-next:hover,
.swiper-button-prev:hover {
    background-color: rgba(255, 255, 255, 0.5); 
    color: #894526;
}
 */

 .container {
  width: 100%;
  height: 95vh;
  position: relative;
  overflow: hidden;
}

.swiper_container {
  width: 100%;
  height: 100%;
}

.swiper-slide {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  position: relative;
  /* background-color: rgba(0, 0, 0, 0.3); */
}

.main-slogan {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  text-align: center;
  color:  white;
  padding: 20px;
  background-color: transparent;
  border-radius: 10px;
  z-index: 2; 
  pointer-events: none; 
}

/* #F4A35D */
/* #E77241 */
/* #E88606 */
/* #D75F02 */
/* #BB4802 */
/* #F8CC6C */
/* #cc5402 */

.main-slogan h2{
  width: 70vw;
  font-size: 3.2rem;
  margin-top: 100px;
  border-radius: 20px;
  padding: 2px;
  background-color: rgba(0, 0, 0, 0.582)
}

.swiper-slide img {
  width: 100%;
  height: auto;
  border-radius: 10px;
  object-fit: cover;
  transition: transform 0.6s ease;
  aspect-ratio: 16/9;
}

.swiper-slide::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border-radius: 10px;
  /* background-color: rgba(0, 0, 0, 0.3); */
  z-index: 1; 
  pointer-events: none;
}


@keyframes backwardForward {
  0% {
    transform: scale(1) translateZ(0);
    opacity: 1;
    /* background-color: rgba(0, 0, 0, 0.3); */
  }
  30% {
    transform: scale(0.9) translateZ(-100px);
    opacity: 0.8;
    /* background-color: rgba(0, 0, 0, 0.3); */
  }
  60% {
    transform: scale(1.1) translateZ(50px);
    opacity: 1;
    /* background-color: rgba(0, 0, 0, 0.3); */
  }
  100% {
    transform: scale(1) translateZ(0);
    opacity: 1;
    /* background-color: rgba(0, 0, 0, 0.3); */
  }
}

.fade-in {
  animation: backwardForward 2s ease-in-out forwards;
  /* background-color: rgba(0, 0, 0, 0.5);  */
}

.fade-out {
  animation: none;
}


.swiper-button-next,
.swiper-button-prev {
  color: #fff;
  font-size: 30px;
  width: 40px;
  height: 40px;
  background-color: rgba(0, 0, 0, 0.5);
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: background-color 0.3s ease;
  z-index: 3; 
}

.swiper-button-next:hover,
.swiper-button-prev:hover {
  background-color: rgba(255, 255, 255, 0.5);
  color: #894526;
}

.swiper-slide::after {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border-radius: 10px;
  z-index: 1;
  pointer-events: none;
  /* background-color: rgba(0, 0, 0, 0.3); */
}
