/* .info-box-wrapper {
  position: relative;
  top: -430px;
  left: 650px;
  color: white;
  padding: 10px;
  width: 30vw;
  background-color: black; 
    border: 5px solid #e77241;
  border-radius: 20px;
  cursor: pointer;
} */
.info-box-wrapper {
  position: relative;
  top: -430px;
  left: 650px;
  color: white;
  padding: 10px;
  width: 30vw;
  background: rgba(255, 255, 255, 0.1); /* Semi-transparent white */
  backdrop-filter: blur(10px); /* Blur for glass effect */
  -webkit-backdrop-filter: blur(10px); /* Blur for Safari support */
  border: 3px solid rgba(255, 255, 255, 0.2); /* Subtle border for edge highlight */
  border-radius: 20px; /* Rounded corners */
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2); /* Slight shadow for depth */
  cursor: pointer;
}

.facility-court{
  width: calc(100% - 140px);
  padding: 0px 30px;
  overflow: hidden;
  margin-bottom: 0;
  margin: 20px auto;
  position: absolute;
  height: 95vh;
  opacity: 0.3;
  /* filter: blur(3px); */
}

.info-box-wrapper h4{
  color: white;
  text-align: center;
  font-size: 1.5rem;
  margin: 0;
  font-weight: 900;
  /* -webkit-text-stroke: 1px #e77241; */

}

.info-box-wrapper p {
  color: white;
  text-align: center;
}

.main-facility {
  position: relative;
  top: 150px;
  left: 120px;
}

.facility-head {
  width: calc(100% - 140px);
  margin: 0px auto;
  height: 130vh;
  padding: 0px 30px;
  overflow: hidden;
  margin-bottom: 0;
  position: relative;
}

.facility-head h2 {
  color: #333333;
  text-align: center;
  font-size: 8rem;
  margin: 0% auto;
  font-weight: 700;
  -webkit-text-stroke: 3px #666666;
}
.facility-head h6 {
  color: #333333;
  text-align: center;
  font-size: 3.3rem;
  margin: 0% auto;
  font-weight: 700;
  /* -webkit-text-stroke: 1px #e77241; */
}

.logo-facility {
  width: 9vw;
  height: 18vh;
  margin: -5px 50px;
  position: relative;
  top: 10px;
  filter: brightness(70%);
  animation: ballRotate 40s linear infinite;
}

@keyframes ballRotate {
  from {
      transform:  rotate(0deg);
  }
  to {
      transform:  rotate(360deg);
  }
}


/* .main-facility {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
    background-color: black;
  }
  
  .circular-text-wrapper {
    position: relative;
    width: 300px;
    height: 300px;
  }
  
  #circle-path {
    fill: transparent;
  }
  
  .circular-text {
    font-size: 18px;
    fill: white;
    animation: spin-text 5s linear infinite;
    transform-origin: center;
  }
  
  @keyframes spin-text {
    from {
      transform: rotate(0deg);
    }
    to {
      transform: rotate(360deg);
    }
  } */
