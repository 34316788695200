.more-about {
  display: flex;
  flex-direction: row;
  margin-bottom: 50px;
  width: calc(100% - 140px);
  height: 105vh;
  margin: 30px auto;
  padding: 0px 30px;
  box-sizing: border-box;
  overflow-x: hidden;
  background-color: black;
  position: relative;
}

.backgroundImg {
  width: 100%;
  height: 80vh;
  filter: brightness(65%) blur(2px);
  object-fit: cover;
}

.moreabout-question {
  position: absolute;
  display: flex;
  flex-direction: row;
  margin: 0;
  gap: 10px;
}

.moreabout-imgtext {
  position: absolute;
}

.moreabout-imgtext p {
  color: white;
  align-items: center;
  position: relative;
  top: 310px;
  left: 30px;
  padding: 20px;
  border-radius: 20px;
}

.joinUsImage {
  position: absolute;
  height: 50vh;
  width: 40vw;
  border-radius: 30px;
  margin: 30px 50px;
  object-fit: cover;
  border: 3px solid white;
}

.basketball-iconmore {
  color: white;
  margin: 20px;
  margin-right: 0;
  font-size: 1.5rem;
  position: absolute;
  left: 710px;
}

.moreabout-question h4 {
  position: relative;
  color: white;
  left: 770px;
  width: 7vw;
  text-align: left;
}

.moreabout-data {
  position: absolute;
  left: 55%;
  top: 7%;
  color: white;
}

.moreabout-data p {
  line-height: 1.6rem;
}
.playernum-data {
  display: flex;
  flex-direction: row;
  gap: 20px;
  max-width: 100%;
  /* width: 25vw; */
  border: 5px solid #e77241;
  background-color: white;
  color: #e77241;
  padding: 10px;
  border-radius: 20px 0px 20px 0px;
  text-align: center;
  position: absolute;
  right: 32%;
  top: 42%;
  padding: 0 20px;
  height: 18vh;
}

hr {
  background-color: #e77241;
  color: #e77241;
  /* width: 0.1px; */
  margin: 10px;
}

.digit {
  font-size: 36px;
  font-weight: bold;
  line-height: 0.5rem;
}

/* .moreabout-cards {
  display: flex;
  flex-direction: row;
  position: absolute;
  width: 80vw; 
  height: 20vh;
  top: 70%;
  left: 60%; 
  transform: translateX(-55%);
  color: white;
  gap: 40px;
}

.moreabout-carddetail {
  position: absolute;
  top: 30%;
  width: 20vw;
  text-align: center;
}

.moreabout-card {
  border: 1px solid white;
  width: 20vw;
  height: 35vh;
}

.moreabout-cards img {
  width: 20vw;
  height: 35vh;
} */

.moreabout-cards {
  display: flex;
  flex-direction: row;
  position: absolute;
  width: 80vw;
  max-height: 100%;
  top: 65%;
  left: 60%;
  transform: translateX(-55%);
  color: #e77241;
  gap: 40px;
  overflow: hidden;
}

.moreabout-card {
  width: 20vw;
  height: 35vh;
  border: 3px solid rgba(255, 255, 255, 0.2);;
  position: relative;
  overflow: hidden;
  display: flex;
  flex-direction: row;
  justify-content: center;
  border-radius: 30px;
  align-items: center;
  background-color: rgba(0, 0, 0, 0.8);
  color:#e77241;
  transition: background-image 0.3s ease, color 0.3s ease;
}

.moreabout-card img {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
  opacity: 0; /* Initially hide the background image */
  transition: opacity 0.3s ease;
}

.moreabout-card:hover img {
  opacity: 1; /* Show the image on hover */
}

.moreabout-icon {
  width: 3vw;
  height: 5vh;
  padding: 5px;
  border: 1px solid white;
  border-radius: 50%;
}

.moreabout-carddetail {
  position: relative;
  text-align: center;
  z-index: 2; /* Ensures text stays above the image */
}

.moreabout-card:hover {
  background-color: transparent; /* Make the background color disappear on hover */
}

.moreabout-card:hover .moreabout-carddetail {
  color: white;
}
