.coaches-maindiv {
  width: calc(100% - 140px);
  height: 70vh;
  margin: 0px auto;
  padding: 0px 30px;
  overflow-x: hidden;
  margin-bottom: 0;
}
.coach-head {
  color: #333333;
  text-align: center;
  font-size: 10rem;
  margin: 0px auto;
  font-weight: 700;
  /* -webkit-text-stroke: 5px #666666; */
}
.coaches-subhead {
  color: #333333;
  text-align: center;
  font-size: 3rem;
  margin: 30px auto;
  font-weight: 700;
  /* border-bottom: 2px solid white; */
  width: 81vw;
  margin-top: 0;
  -webkit-text-stroke: 2px #666666;
}

.marquee {
  color: #333333;
  text-align: center;
  font-size: 14rem;
}

.coaches-div {
  display: flex;
}

.coaches-textArea {
  color: whitesmoke;
  display: flex;
  flex-direction: column;
}

.coaches-subtext {
text-align: center;
}

.coachImg {
  position: absolute;
  height: 50vh;
  width: 40vw;
  border-radius: 10px;
  margin: 30px 50px;
  object-fit: cover;
}

.coaches-text {
  display: flex;
  flex-direction: row;
  gap: 10px;
}

.coaches-headtext {
  justify-items: flex-start;
}
.coaches-headtext h3 {
  color: #e77241;
}

.coaches-onetwo {
  display: flex;
  flex-direction: row;
  gap: 20px;
  align-self: flex-start;
}

.count {
  position: relative;
  top: 20px;
  font-size: 2.5rem;
  font-weight: 500;
  -webkit-text-stroke: 2px #e77241;
}

.coaches-threefour {
  display: flex;
  flex-direction: row;
  gap: 20px;
  align-self: flex-start;
  margin-top: 20px;
}

.coaches-imagetext {
  color: white;
  position: relative;
  bottom: 50px;
}

.coaches-imagetext p {
  width: 100%;
  background-color: black;
  position: relative;
  top: 75%;
  left: 18%;
  padding: 20px;
  border-radius: 10px;
}

.coaches-blank {
    width: 10vw;
    height: 8vh;
    background-color: black;
    transform: rotate(-45deg);
    position: relative;
    top: 15%;
    z-index: 2;
}
