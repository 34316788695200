/* .footer {
    max-width: 100%;
    height: 50vh;
    background: rgba(255, 255, 255, 0.1);
    backdrop-filter: blur(10px); 
    -webkit-backdrop-filter: blur(10px); 
    border: 3px solid rgba(255, 255, 255, 0.2);
    background-image: url("../../Assets/bgthree.jpg");
    background-size: cover;
    background-position: center;
    background-attachment: fixed;
    background-repeat: no-repeat; 
    overflow-x: hidden;
  } */

.footer {
  max-width: 100%;
  height: 50vh;
  position: relative; /* For proper positioning of child elements */
  overflow: hidden;
  /* background: url("../../Assets/bgthree.jpg"); */
  background: url("../../Assets/carousel2.jpg");
  opacity: 0.7;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  margin-top: 5%;
}

.footer-div {
  background: rgba(0, 0, 0, 0.3); /* Semi-transparent white for glass effect */
  backdrop-filter: blur(5px);
  -webkit-backdrop-filter: blur(10px); /* Safari support */
  border: 3px solid rgba(255, 255, 255, 0.2); /* Subtle border for edge highlight */
  padding: 20px; /* Adds some space inside the div */
  border-radius: 15px; /* Adds rounded corners */
  width: 95%;
  height: 42vh; /* Adjust the size of the glass effect area */
  margin: 5px auto; /* Centers the .footer-div horizontally */
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  /* flex-wrap: wrap;  */
  color: white; /* Text color for contrast */
  box-shadow: 0 8px 32px rgba(0, 0, 0, 0.3); /* Optional shadow for more depth */
}

.footer-logo {
  width: 13vw;
  height: 15vh;
}

.footer-section1 {
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.footer-section1 p {
  font-size: 1rem;
  font-weight: 500;
}

.footer-section2 {
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.footer-section2 a {
  text-decoration: none;
  color: white;
  cursor: pointer;
  font-weight: 700;
}

.footer-section2 a:hover{
  color: #ff6b00;;
}

.footer-section3 {
  display: flex;
  flex-direction: column;
}
.footer-section4 {
  display: flex;
  flex-direction: column;
}

.footerblack-div {
  width: 10vw;
  height: 8vh;
  background-color: black;
  transform: rotate(-45deg);
  position: absolute;
  bottom: -28px;
  right: 440px;
  z-index: 0;
}

.socialmedia-icon {
  display: flex;
  flex-direction: row;
  gap: 30px;
}

.icon-footer{
  border: 2px solid white;
  font-size: 2.5rem;
  background-color: transparent;
  border-radius: 10px;
  cursor: pointer;
}

.icon-footer:hover{
  color: #ff6b00;;
  border: 2px solid #ff6b00;
}

.copyright {
  font-size: 1rem;
  color: white;
  position: absolute;
  bottom: 0;
  right: 0;
  background-color: black;
  padding: 10px;
}

.copyright p {
  margin-left: 80px;
}

.footer-section2 h2{
  color: #ff6b00;
}
.footer-section3 h2{
  color: #ff6b00;
}
.footer-section4 h2{
  color: #ff6b00;
}